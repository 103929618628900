import Routes from '../routes';

const NavigationItemsList = [
  {title: 'Startseite', route: Routes.Home},
  {title: 'Angebot', route: Routes.Offer},
  {title: 'Blog', route: Routes.Blog},
  {title: 'Über mich', route: Routes.About},
  {title: 'Kontakt', route: Routes.Contact},
];

export default NavigationItemsList;
