const ImpressDataProtection = () => {
  return (
    <div className='impressum'>
      <div className='impressum__container container'>
        <h2 id='datenschutz'>Impressum</h2>
        <div>
          <p>Inhaber: Raphael Lautenbacher</p>
          <p>Anschrift: Auf der Insel 7, 86972 Altenstadt</p>
          <p>Steuernummer: 168-243-70706</p>
          <p>Telefon: <a href="tel:+4917687650818">017687650818</a></p>
          <p>Email: <a href="lautenbacher-coaching@protonmail.com">lautenbacher-coaching@protonmail.com</a></p>
        </div>
        <h2 id='datenschutz'>Datenschutz</h2>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <a href='#kommunikation-via-e-mail-und-kontaktformular'>1. Kommunikation mit mir</a>
          <a href='#zugriffsdaten-und-hosting'>2. Zugriffsdaten und Hosting</a>
          <a href='#datenerhebung-und-verwendung'>
            3. Datenerhebung und -verwendung zur Nutzung der Lautenbacher Coaching Website
          </a>
          <a href='#cookies-und-webanalyse'>4. Cookies und Webanalyse</a>
          <a href='#onlinepraesenzen-auf-social-media'>
            5. Unsere Onlinepräsenzen auf Social-Media-Plattformen
          </a>
          <a href='#kontaktmoeglichkeiten-und-rechte'>6. Kontaktmöglichkeiten und Ihre Rechte</a>
          <a href='#aenderung-datenschutzbestimmungen'>7. Änderung der Datenschutzbestimmungen</a>
          <a href='#widerspruchsrecht'>Allgemeines Widerspruchsrecht</a>
        </div>

        <p>
          Ich freue mich über Ihr Interesse an unserer Webseite. Nachstehend informiere ich Sie
          ausführlich über den Umgang mit Ihren Daten.
        </p>

        <p id='kommunikation-via-e-mail-und-kontaktformular'>
          <strong>1. Kommunikation mit uns</strong>
        </p>

        <p>
          <strong>E-Mail und Kontaktformular</strong>
        </p>

        <p>
          Wir erheben personenbezogene Daten, wenn Sie uns diese bei einer Kontaktaufnahme mit uns
          (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als
          solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Bearbeitung Ihrer
          Kontaktaufnahme oder Eröffnung des Kundenkontos benötigen und Sie ohne deren Angabe die
          Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den
          jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten
          zur Bearbeitung Ihrer Anfragen. Rechtsgrundlage ist Art 6 Abs. 1 S. 1 lit. b DSGVO, soweit
          Ihre Kontaktanfrage im Zusammenhang mit eine bestehenden oder sich anbahnenden Vertrag mit
          Ihnen steht. Ansonsten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO, da die
          Verarbeitung zur Erfüllung unseres berechtigten Interesses an einer ordnungsgemäßen
          Bearbeitung von Kontaktanfragen erforderlich ist. Um Anfragen ordnungsgemäß zuordnen zu
          können, ist hierbei die Angabe personenbezogener Daten erforderlich.
        </p>

        <p>
          Ich verwende die von Ihnen mitgeteilten Daten zur Terminbestätigung, zur Bearbeitung
          Ihrer Anfragen und zur Wahrnehmung des Termins. Der Zweck der Verarbeitung liegt in der
          Planung und Wahrnehmung von Terminen mit Ihnen. Rechtsgrundlage ist Art 6 Abs. 1 S. 1 lit.
          b DSGVO, soweit Ihre Kontaktanfrage im Zusammenhang mit einem bestehenden oder sich
          anbahnenden Vertrag mit Ihnen steht. Ansonsten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1
          lit. f DSGVO. Die Verarbeitung ist zur Erfüllung unseres berechtigten Interesses an einer
          ordnungsgemäßen Bearbeitung von Kontaktanfragen, Terminvergabe und Terminbuchung
          erforderlich.
        </p>

        <p>
          Nach vollständiger Abwicklung des Termins werden Ihre Daten für die weitere Verarbeitung
          eingeschränkt und, sofern einschlägig, nach Ablauf der steuer- und handelsrechtlichen
          Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer
          Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung
          vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung
          informieren.
        </p>

        <p>
          <strong>Virtuelle Meetings</strong>
        </p>

        <p>
          Für virtuelle Meetings werden Online-Meetings (Telefon-/Videokonferenzen) mit Ihnen
          durchgeführt. Alternativ können Sie uns auch per Telefonanruf kontaktieren. Wenn Sie die
          Möglichkeit eines Online-Meetings über Microsoft Teams, Zoom, Google Meets mit uns nutzen
          möchten, können Sie entscheiden. Wenn Sie die Möglichkeit der Teilnahme über die
          Browserlösung nutzen, oder sich die App über die Webseite runterladen, weisen wir darauf
          hin, dass die Anbieter für das Erheben der Daten über diese Seiten verantwortlich ist.
        </p>

        <p>
          Folgende Daten werden bei der Teilnahme an einem Online-Meeting verwenden: Ihre
          E-Mailadresse, wenn von Ihnen eingestellt. Darüber hinaus werden sog. Metadaten erfasst,
          wie Datum, Uhrzeit. Zudem können Sie an dem Meeting mit oder ohne Video und mit oder ohne
          Ton teilnehmen und darüber hinaus auch die Chatfunktion nutzen und hierüber Text oder
          Dateien senden. Soweit Sie diese Funktionen nutzen, werden Bild- und Tondateien sowie die
          über die Chatfunktion versendeten Daten verarbeitet. Grundsätzlich werden die Meetings
          nicht aufgezeichnet. Sollte dies doch einmal der Fall sein, werden Sie zuvor darauf
          hingewiesen und um Ihre Einwilligung gebeten.
        </p>

        <p>
          Zweck der Datenverarbeitung ist die Durchführung von Online-Meetings sowie ggfs. der
          Austausch von Daten über die Chatfunktion.
        </p>

        <p>
          Rechtsgrundlage ist Art 6 Abs. 1 S. 1 lit. b DSGVO, soweit Ihre Kontaktanfrage im
          Zusammenhang mit eine bestehenden oder sich anbahnenden Vertrag mit Ihnen steht. Ansonsten
          ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Verarbeitung ist zur
          Erfüllung unseres berechtigten Interesses an einer guten Kontaktaufnahme mit uns sowie
          einem einfachen und persönlichen Austausches mit Ihnen erforderlich.
        </p>

        <p>
          Personenbezogene Daten, die im Zusammenhang mit der Teilnahme an einem Online-Meeting
          verarbeitet werden, werden nicht an Dritte weitergegeben, es sei denn diese sind zum
          Zwecke der Weitergabe übermittelt worden.
        </p>

        <p></p>

        <p id='zugriffsdaten-und-hosting'>
          <strong>2. Zugriffsdaten und Hosting</strong>
        </p>

        <p>
          Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem
          Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes
          Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und
          Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten)
          enthält und den Abruf dokumentiert.
        </p>

        <p>
          Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines
          störungsfreien Betriebs der Seite sowie der Verbesserung meines Angebots ausgewertet.
          Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
          berechtigten Interessen an einer korrekten Darstellung unseres Angebots nach Art. 6 Abs. 1
          S. 1 lit. f DSGVO. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres
          Seitenbesuchs gelöscht.
        </p>

        <p>
          Alle Daten, die im Rahmen der Nutzung dieser Webseiten oder in dafür vorgesehenen
          Formularen wie folgend beschrieben erhoben werden, werden auf den Servern des jeweiligen
          Dienstleisters verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in dem hier
          erläuterten Rahmen statt.
        </p>

        <p id='datenerhebung-und-verwendung'>
          <strong>
            3.{' '}
            <strong>
              Datenerhebung und -verwendung zur Nutzung der Lautenbacher Coaching Website
            </strong>
          </strong>
        </p>

        <p>
          <strong>Telefon-/Video-Interviews im Zusammenhang mit der Produktnutzung</strong>
        </p>

        <p>
          Soweit Sie mir Ihre Einwilligung erteilt haben, Sie zwecks Durchführung eines Interviews
          zu kontaktieren, sende ich Ihnen per E-Mail einen Link und das weitere Vorgehen.
        </p>

        <p>
          Nachdem Sie einen Termin ausgemacht haben, kontaktieren ich Sie zum vereinbarten
          Zeitpunkt, je nach Ihrer Auswahl, entweder telefonisch oder per Video- oder Telefonanruf.
          Detaillierte Informationen zur Verarbeitung Ihrer Daten über Videoanrufe finden Sie oben
          unter dem entsprechenden Abschnitt.
        </p>

        <p>
          Das Interview wird durch mich, Raphael Lautenbacher, durchgeführt. Die Antworten des
          Interviews werden ausschließlich für interne Zwecke zur Verbesserung der Produkte
          gespeichert. Zu den gegebenen Antworten werden nur Angaben zum Alter und Geschlecht der
          Interviewpartner gespeichert. Jedoch keine sonstigen personenbezogenen Daten, wie Name,
          E-Mailadresse oder Telefonnummer.
        </p>

        <p>
          Zweck der Verarbeitung Ihrer Daten ist die Terminvereinbarung sowie die Durchführung eines
          Interviews mit Ihnen, um hierdurch Rückschlüsse auf unsere Produkte zu erhalten.
        </p>

        <p>Eine Weitergabe Ihrer Interviewdaten an Dritte erfolgt nicht.</p>

        <p>
          Rechtsgrundlage für die Kontaktaufnahme mit Ihnen zwecks Terminvereinbarung und
          Durchführung des Interviews ist Ihre diesbezügliche Einwilligung, Art. 6 Abs. 1 lit. a
          DSGVO.
        </p>

        <p>
          Sie haben das jederzeitige Recht die Einwilligung zu widerrufen, ohne dass die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          berührt wird.
        </p>

        <p>
          Ihre personenbezogenen Daten, die Sie im Zusammenhang mit der Terminvereinbarung und dem
          Interview angegeben haben, werden nach Ablauf der gesetzlichen Aufbewahrungsfristen
          gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt
          haben.
        </p>

        <p id='cookies-und-webanalyse'>
          <strong>4. Cookies und Webanalyse</strong>
        </p>

        <p>
          Um den Besuch meiner Webseite attraktiv zu gestalten und die Nutzung bestimmter
          Funktionen zu ermöglichen, um passende Produkte anzuzeigen oder zur Marktforschung
          verwenden wir auf verschiedenen Seiten sogenannte Cookies und andere Technologien, bei
          denen Informationen auf Ihrem Endgerät abgelegt oder von Ihrem Endgerät abgerufen werden
          (im Folgenden einheitlich als „Cookies“ bezeichnet).
        </p>

        <p>Cookies:</p>

        <p>
          Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert werden.
          Manche Cookies setzen wir immer ein, um die grundlegende Funktionalität unserer Seiten und
          die optimierte Darstellung unseres Angebots gewährleisten zu können
          (essentielle/notwendige Cookies). Einige der von uns verwendeten Cookies werden nach Ende
          der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog.
          Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns, Ihren
          Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies).
        </p>

        <p>Consent-Manager:</p>

        <p>
          Soweit Sie Ihre Einwilligung in den Einsatz von Cookies nach Art. 6 Abs. 1 S. 1 lit. a
          DSGVO erteilt haben, können Sie Ihre Einwilligung durch Abwählen im Cookie-Manager rückgängig machen.
          
        </p>

        <p>
          Bei Nichtannahme von Cookies ist die Funktionalität meiner Webseite nicht eingeschränkt.
        </p>

        <p>Youtube Video Plugins</p>

        <p>
          Auf dieser Webseite werden möglicherweise Inhalte über den Video-Plattform-Dienst Youtube
          eingebunden. Dies dient der Wahrung meiner im Rahmen einer Interessensabwägung
          überwiegenden berechtigten Interessen an einer optimalen Darstellung unseres Angebots
          gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
        </p>

        <p>
          Youtube&nbsp;wird&nbsp;betrieben&nbsp;von der Google LLC, 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA („Google“).
        </p>

        <p>
          Bei Videos von Youtube, die auf meiner Seite eingebunden sind, ist die erweiterte
          Datenschutzeinstellung aktiviert. Das bedeutet, dass keine Informationen von
          Webseitenbesuchern bei Youtube erhoben und gespeichert werden, es sei denn, diese spielen
          das Video ab. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung
          der Daten durch die Anbieter sowie Ihre diesbezüglichen Rechte und
          Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den
          Datenschutzhinweise von Google&nbsp;
          <a href='http://www.google.com/intl/de/+/policy/+1button.html'>Google button</a>.
        </p>

        <p id='onlinepraesenzen-auf-social-media'>
          <strong>5. Meine Onlinepräsenzen auf Social-Media-Plattformen</strong>
        </p>

        <p>
          Meine Präsenzen auf sozialen Netzwerken und Plattformen dienen einer besseren, aktiven
          Kommunikation mit meinen Kunden und Interessenten. Ich informiere dort über meine
          Produkte und laufende Sonderaktionen.
        </p>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div>
            Google/YouTube:&nbsp;
            <a href='https://policies.google.com/privacy' target='_blank' rel='noreferrer noopener'>
              Google Datenschutzerklärung
            </a>
          </div>
          <div>
            LinkedIn:&nbsp;
            <a
              href='https://www.linkedin.com/legal/privacy-policy'
              target='_blank'
              rel='noreferrer noopener'>
              LinkedIn Datenschutzerklärung
            </a>
          </div>
          <div>
            Xing:&nbsp;
            <a
              href='https://privacy.xing.com/de/datenschutzerklaerung'
              target='_blank'
              rel='noreferrer noopener'>
              Xing Datenschutzerklärung
            </a>
          </div>
        </div>

        <p>Widerspruchsmöglichkeiten (Opt-Out):</p>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div>
            Google/YouTube:&nbsp;
            <a
              href='https://adssettings.google.com/authenticated'
              target='_blank'
              rel='noreferrer noopener'>
              Google Widerspruch
            </a>
          </div>
          <div>
            LinkedIn:&nbsp;
            <a
              href='https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out'
              target='_blank'
              rel='noreferrer noopener'>
              LinkedIn Widerspruch
            </a>
          </div>
          <div>
            Xing:&nbsp;
            <a
              href='https://privacy.xing.com/de/datenschutzerklaerung/welche-rechte-koennen-sie-geltend-machen/widerspruchsrecht'
              target='_blank'
              rel='noreferrer noopener'>
              Xing Widerspruch
            </a>
          </div>
        </div>

        <p id='kontaktmoeglichkeiten-und-rechte'>
          <strong>6. Kontaktmöglichkeiten und Ihre Rechte</strong>
        </p>

        <p>Als Betroffener haben Sie folgende Rechte:</p>

        <ul style={{listStyleType: 'none'}}>
          <li>
            gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von
            uns verarbeiteten personenbezogenen Daten zu verlangen;
          </li>
          <li>
            gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder
            Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
          </li>
          <li>
            gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten
            personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung
            <ul style={{listStyleType: 'none'}}>
              <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
              <li>zur Erfüllung einer rechtlichen Verpflichtung;</li>
              <li>aus Gründen des öffentlichen Interesses oder</li>
              <li>
                zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
                ist;
              </li>
            </ul>
          </li>
          <li>
            gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen, soweit
            <ul style={{listStyleType: 'none'}}>
              <li>die Richtigkeit der Daten von Ihnen bestritten wird;</li>
              <li>die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;</li>
              <li>
                wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen benötigen oder
              </li>
              <li>Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
            </ul>
          </li>
          <li>
            gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt
            haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder
            die Übermittlung an einen anderen Verantwortlichen zu verlangen;
          </li>
          <li>
            gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der
            Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes
            oder Arbeitsplatzes wenden.
          </li>
        </ul>

        <p>
          Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei
          Auskünften, Berichtigung, Einschränkung der Verarbeitung oder Löschung von Daten sowie
          Widerruf ggf. erteilter Einwilligungen oder Widerspruch gegen eine bestimmte
          Datenverwendung wenden Sie sich bitte an unseren betrieblichen Datenschutzbeauftragten.
        </p>
        <p id='aenderung-datenschutzbestimmungen'>
          <strong>7. Änderung meiner Datenschutzbestimmungen</strong>
        </p>
        <p>
          Ich behalte mir vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen
          rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
          Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren
          erneuten Besuch gilt dann die neue Datenschutzerklärung.
        </p>

        <hr className='wp-block-separator is-style-wide' />

        <p id='widerspruchsrecht'>
          <strong>
            Widerspruchsrecht
            <br />
            Soweit ich zur Wahrung meiner im Rahmen einer Interessensabwägung überwiegenden
            berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können
            Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein
            Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation
            ergeben, zu. Nach Ausübung Ihres Widerspruchsrechts werde ich Ihre personenbezogenen
            Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, ich kann zwingende
            schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
            Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen dient.
          </strong>
        </p>
      </div>
    </div>
  );
};

export default ImpressDataProtection;
