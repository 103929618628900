import {NavLink} from 'react-router-dom';
import {useState} from 'react';

import MyRoutes from '../routes';
import CookieConsent from 'react-cookie-consent';

const CookieBanner = () => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <CookieConsent
      debug={true}
      location='bottom'
      buttonText='Verstanden'
      cookieName='myAwesomeCookieName2'
      style={{
        fontSize: '16px',
      }}
      buttonStyle={{
        color: '#fff',
        background:
          'radial-gradient(circle, rgba(158,35,156,0.9598214285714286) 0%, rgba(54,102,208,0.9710259103641457) 100%)',
        fontSize: '16px',
      }}
      expires={365}>
      Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können,
      verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von
      Cookies zu.
      <br />
      <span style={{fontSize: '10px'}}>
        <NavLink
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            color: isHovering
              ? 'rgba(158,35,156,0.9598214285714286)'
              : 'rgba(54,102,208,0.9710259103641457)',
            textDecoration: isHovering ? 'underline' : 'none',
          }}
          to={MyRoutes.ImpressumDataProtection}>
          Datenschutzerklärung
        </NavLink>
      </span>
    </CookieConsent>
  );
};

export default CookieBanner;
