import {NavLink} from 'react-router-dom';
import itemsList from '../data/navitem-list';

function NavigationItemsMobile(props) {
  return (
    <div className='nav-items'>
      <ul className='nav-items__list'>
        {itemsList.map((item, index) =>
            <li
              key={index}
              onClick={props.clickToCloseMenu}
              className={`nav-items__list-item ${props.isOpen && 'nav-items__list-item--open'}`}>
              <NavLink className='nav-items__nav-item' to={item.route}>
                {item.title}
              </NavLink>
            </li>
        )}
      </ul>
    </div>
  );
}

export default NavigationItemsMobile;
