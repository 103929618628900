const Routes = {
  Home: '/',
  About: '/ueber-mich',
  Contact: '/kontakt',
  Error: '/error',
  Offer: '/angebot',
  Blog: '/blog',
  AGB: '/agb',
  ImpressumDataProtection: '/impressum-datenschutz',
};

export default Routes;
