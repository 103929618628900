import {NavLink} from 'react-router-dom';

import BurgerMenu from './mobile-menu';
import DesktopMenu from './desktop-menu';

import {ReactComponent as LogoText} from '../media/logo/lautenbacher-coaching-logo-text.svg';
import {ReactComponent as LogoIcon} from '../media/logo/lautenbacher-coaching-logo-icon-2.svg';

function Header() {
  return (
    <div className='header'>
      <NavLink to='/' className='header__logo-wrapper'>
        <LogoIcon className='header__logo-icon' />
        <LogoText className='header__logo-text' />
      </NavLink>
      <BurgerMenu />
      <DesktopMenu />
    </div>
  );
}

export default Header;
