import AsyncImage from './async-image';

const HeaderImage = (props) => {
  return (
    <>
      <div className='headerImage'>
        <div className='headerImage__image-teaser-wrapper'>
          <div className='headerImage__title-wrapper'>
            <h1
              style={{display: !props.headerImageTitle && 'none'}}
              className={`headerImage__main-title headerImage__main-title--color-${
                props.headerImageTitleColor ? props.headerImageTitleColor : 'black'
              }`}>
              {props.headerImageTitle}
            </h1>
            <AsyncImage
              className='headerImage__image-title'
              src={props.headerTitleImage}
              alt={props.headerTitleImageAlt}
            />
          </div>
          <figure className='headerImage__image-wrapper'>
            <AsyncImage
              className='headerImage__image'
              src={props.headerImage}
              alt={props.headerImageAlt}
              fetchpriority={!props.headerFetchPriority ? 'low' : props.headerFetchPriority}
            />
          </figure>
        </div>
      </div>
    </>
  );
};

export default HeaderImage;
