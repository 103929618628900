import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Blog from '../pages/blog';

//import custom Routes
import MyRoutes from '../routes';

const BlogPost = ({ posts }) => {
  const { slug } = useParams();
  if (!posts) return <Blog />;
  const post = posts.find((post) => post.slug === slug);
  const markup = { __html: post.content.html };

  return (
    <div>
      {!posts ? (
            <Blog />
          ) : (
            <div className='blogpost'>
              <h1 className='blogpost__title'>{post.title}</h1>
              <div className="blogpost__text-container" dangerouslySetInnerHTML={markup}></div>
              <div className='blogpost__button-wrapper'>
                <Link to={MyRoutes.Contact}>
                  <button className='blogpost__button buttonFill'>zum persönlichen Gespräch</button>
                </Link>
                <Link to={MyRoutes.Blog}>
                  <button className='blogpost__button buttonFill'>Zurück</button>
                </Link>
              </div>
            </div>
          )
      }
    </div>
  );
}

export default BlogPost;