import RaphaelImg from '../media/bewerbungsfoto_1.webp';

const AboutList = [
  {
    name: 'Raphael Lautenbacher',
    image: RaphaelImg,
    imageAlt: 'Raphael Lautenbacher',
    title: ['Finanzberater', 'Mental- und Lifecoach', 'Kryptocoach'],
    description: `Hallo zusammen, ich bin Raphael Lautenbacher. 2014, nach meiner aktiven Zeit als erfolgreicher Profisportler im Fallschirmsport habe ich mich für ein Studium der Wirtschaftspsychologie entschieden und mich dabei intensiv mit Psychologie, Wirtschaft und den Schnittmengen daraus beschäftigt. Ein Verständnis für menschliches Verhalten auf individueller Ebene, aber auch die Psychologie der Masse haben mich fasziniert. 
    Im Zuge der wirtschaftlichen Themen im Studium bin ich dabei auf die faszinierende Welt der Blockchain-Technologie und Kryptowährungen gestoßen. Um mein Wissen in diesem Bereich zu vertiefen, habe ich im Jahr 2022 am renommierten "Blockchain Strategy Programme" an der Oxford Universität teilgenommen. Dort konnte ich durch interessante Gastredner aus Industrie und Politik meinen Horizont erheblich erweitern.
    \nDie psychologische Komponente ist jedem Menschen gegeben, ich wollte durch das Studium ein tiefergreifendes Wissen darüber aufbauen. Meine Erfahrungen als Spitzensportler haben dafür gesorgt, dass ich mich viel mit mir auseinandersetzen durfte und musste. Neben meinem akademischen Werdegang habe ich auch eine Ausbildung zum Life Coach und Persönlichkeitstrainer absolviert. Dabei bringe ich meine Erfahrungen als Sportler und meine Kenntnisse in Psychologie zusammen, um anderen Menschen bei der Verwirklichung ihrer Ziele zu helfen oder Klarheit und andere Blickwinkel in ihr Leben zu bringen. 
            `,
  }
];
export default AboutList;
