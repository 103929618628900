import NavigationItemsDesktop from '../fragments/navigation-items-desktop';

function DesktopMenu() {
  return (
    <div className='desktopMenu'>
      <nav className='desktopMenu__nav-link-container'>
        <NavigationItemsDesktop />
      </nav>
    </div>
  );
}

export default DesktopMenu;
