// Import Components
import HeaderImage from '../components/header-image';
import AboutList from '../data/about-list';
import ImageText from '../components/image-text';

// Import Media
import PersonCityBackground2 from '../media/person-staying-infront-city.webp';
import GanzheitlichesCoachingTextSlogan from '../media/logo/ganzheitliches-coaching-text-black.svg';

const About = () => {
  return (
    <div className='aboutme'>
      <HeaderImage
        headerImage={PersonCityBackground2}
        headerImageAlt='Person vor einer Stadt im Hintergrund'
        headerTitleImage={GanzheitlichesCoachingTextSlogan}
        headerTitleImageAlt='Ganzheitliches Coaching Slogan'
      />
      <div className='aboutme__content-container'>
        <div className='aboutme__content-wrapper container'>
          <div>
            <ImageText
              image={AboutList[0].image}
              imageAlt={AboutList[0].imageAlt}
              title={AboutList[0].title}
              name={AboutList[0].name}
              description={AboutList[0].description}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
