import {NavLink} from 'react-router-dom';

import {BsLinkedin, BsTelegram} from 'react-icons/bs';
import {FaWhatsappSquare} from 'react-icons/fa';
import {ImXing} from 'react-icons/im';

import '../styles/components/_footer.scss';
import Routes from '../routes';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer__wrapper'>
        <div className='footer__list-item footer__list-item--dev'>
          © developed by raphaelLautenbacher | Lautenbacher Coaching
        </div>
        <div className='footer__list-container'>
          <div className='footer__list-items footer__list-items--contact'>
            <h5 className='footer__title'>Kontakt</h5>
            <div className='footer__list-item'>
              <a
                className='footer__list-item-link'
                target='_blank'
                rel='noreferrer'
                href='mailto:lautenbacher-coaching@protonmail.com'>
                lautenbacher-coaching@ protonmail.com
              </a>
            </div>
            <div className='footer__list-item'>
              <a className='footer__list-item-link' href='tel:+4917668750818'>
                Mobil: 017668750818
              </a>
            </div>
            <div className='footer__list-item'>
              <NavLink className='footer__list-item-link' to={Routes.Contact}>
                Termin vereinbaren
              </NavLink>
            </div>
          </div>
          <div className='footer__list-items'>
            <h5 className='footer__title'>Social media</h5>
            <div className='footer__list-item'>
              <a
                className='footer__list-item-link footer__list-item-link--social-media'
                target='_blank'
                rel='noreferrer'
                href='https://www.xing.com/profile/Raphael_Lautenbacher/portfolio'>
                <ImXing />
                <div>Xing</div>
              </a>
            </div>
            <div className='footer__list-item'>
              <a
                className='footer__list-item-link footer__list-item-link--social-media'
                target='_blank'
                rel='noreferrer'
                href='https://www.linkedin.com/in/raphael-lautenbacher-4940a6171/'>
                <BsLinkedin />
                <div>LinkedIn</div>
              </a>
            </div>
            <div className='footer__list-item'>
              <a
                className='footer__list-item-link footer__list-item-link--social-media'
                target='_blank'
                rel='noreferrer'
                href='https://telegram.me/lautenbacher_coaching'>
                <BsTelegram />
                <div>Telegram chat</div>
              </a>
            </div>
            <div className='footer__list-item'>
              <a
                className='footer__list-item-link footer__list-item-link--social-media'
                target='_blank'
                rel='noreferrer'
                href='https://wa.me/4917668750818'>
                <FaWhatsappSquare />
                <div>WhatsApp Chat</div>
              </a>
            </div>
          </div>
          <div className='footer__list-items footer__list-items--legal'>
            <div className='footer__list-item'>
              <NavLink className='footer__list-item-link' to={Routes.AGB}>
                AGB
              </NavLink>
            </div>
            <div className='footer__list-item'>
              <NavLink className='footer__list-item-link' to={Routes.ImpressumDataProtection}>
                Impressum und Datenschutz
              </NavLink>
            </div>
            <div className='footer__list-item'>
              <NavLink className='footer__list-item-link' to={Routes.Offer}>
                Angebot
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
