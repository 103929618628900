import {NavLink} from 'react-router-dom';
import Routes from '../routes';

// Import Scroll Animation Lib
import Fade from 'react-reveal/Fade';

// Import Components
import HeaderImage from '../components/header-image';
import TitleText from '../components/title-text';

// Import Media
import GraphUpFinger from '../media/graph-up-finger-2.webp';
import Compass from '../media/compass-2.webp';
import MoneyTree from '../media/grow-money.webp';
import AngeboteneLeistungTextSlogan from '../media/logo/angebotene-leistung-text-black.svg';
import MyFoto from '../media/bewerbungsfoto_4.webp';

const Offer = () => {

  return (
    <div className='offer'>
      <HeaderImage
        headerImage={GraphUpFinger}
        headerImageAlt='Stein übereinander gestapelt'
        headerTitleImage={AngeboteneLeistungTextSlogan}
        headerTitleImageAlt='Angebotene Leistung Slogan'
      />
      <div className='offer__container'>
        <div className='offer__text-content-wrapper'>
          <div className='offer__short-summary-container container'>
            <div className='offer__short-summary-wrapper'>
              <Fade left>
                <div className='offer__section-short-summary-image-wrapper'>
                  <figure className='offer__short-summary-image-wrapper'>
                    <img className='offer__short-summary-image' src={MyFoto} alt='Coach Raphael Lautenbacher' />
                  </figure>
                </div>
              </Fade>
              <Fade right big cascade>
                <ul className='offer__short-summary-list-wrapper'>
                  <h3 className='offer__short-summary-list-item-title'>Angebot:</h3>
                  <li className='offer__short-summary-list-item'>1:1 Coachings virtuell oder vor Ort</li>
                  <li className='offer__short-summary-list-item'>Workshops für Gruppen (ab 2 Personen)</li>
                  <li className='offer__short-summary-list-item'>Vorträge als Redner</li>
                </ul>
              </Fade>
            </div>
          </div>
          <Fade left>
            <div className='container'>
              <TitleText
                title='Life Coaching und Persönlichkeitsberatung'
                text='Innere und Äußere Umstände bringen uns neue Herausforderungen und Chancen, die wir meistern und wahrnehmen dürfen. Wir verändern uns und unsere unmittelbare Umwelt. Warum nicht für uns eine Welt erschaffen die besser ist, aber Wie?
                Wir werden tagtäglich mit Informationen, größtenteils negativ, bombardiert. Wer weiß noch was richtig und falsch ist in einer Welt in
                der vieles oftmals keinen Sinn zu ergeben zu scheint. 
                Eine Lösung, sich in sich zurück zu ziehen sich zu besinnen auf die Dinge die wichtig sind. Seine eigene kleine, gesunde Welt in sich zu erschaffen
                um so nach und nach, um sich herum eine Oase des Wohlfühlens zu kreieren. Wir dürfen uns zurückbesinnen auf unsere eigenen Talente und Fähigkeiten. Aber auch
                unsere Gefühle und Emotionen gilt es Aufmerksamkeit zu schenken, uns mit uns und um uns achtsam zu verhalten. Liebe zu sich selbst spiegelt sich auch im Außen wieder.
                Möchte ich einen Neuen Job, fühle ich mich schon lange unwohl mit bestimmten Themen im Leben, gebe diesen aber aus Gründen keine Beachtung, werden diese immer heftiger
                an uns Nagen und uns schlussendlich auszehren, unglücklich machen und auch gesundheitlich angreifen. 
                Um dies zu verhindern gibt es vielerlei Wege sich von diesen Lasten zu befreien,
                sei es angefangen bei einem klärenden Gespräch in der Arbeit, sich mit Mangel/Fülle, Selbstwert das mit Selbstliebe zusammenspielt oder aber mit seinem Inneren Kind auseinanderzusetzen. Diese Themen drängen sich immer mehr auf, wir dürfen diese in der heutigen Zeit nicht weiter hinten angestellen wenn wir etwas für uns und unsere Umwelt ändern möchten.
                Wir leben in einer Zeitenwende, einem Wandel, dieser betrifft
                aber vor allem uns selbst. Befähigen wir uns daran teil zu haben, fängt die Aufgabe bei uns an, und diesen Prozess unterstütze und begleite ich als Coach, Berater, Licht im Dunkel und Kompassnadel.'
              />
            </div>
          </Fade>
          <div className='offer__section-image-description-container container'>
            <Fade right>
              <div className='offer__section-image-wrapper'>
                <figure className='offer__image-wrapper'>
                  <img className='offer__image' src={Compass} alt='Hand hält Kompass' />
                </figure>
              </div>
            </Fade>
            <div className='offer__description-wrapper'>
              <Fade right big cascade>
                <ul className='offer__list-wrapper'>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Familie</span> - Ungereimtheiten in der Familie, unklare Kommunikation und verstrittene Mitglieder, nervt mich, ich will doch nur Harmonie haben, geht das?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Beziehung</span> - warum läuft es auseinander, kann ich nicht wieder ehrliche und direkte Kommunikation in die Beziehung bringen, warum werde ich nicht mehr Wahrgenommen von meinem Partner?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Arbeit</span> - mein Chef und die Mitarbeiter nerven mich, ich möchte mich neu definieren und etwas verändern, aber ich traue mich nicht, geht sowas denn so einfach?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Veränderung</span> - das schönste und gleichzeitg beängstigenste, kann ich es wirklich, kann ich mich denn überhaupt etwas verändern, und wenn wie?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Mentale Konzepte (Bewerbungsgespräch, Spitzensport etc.)</span> - wie erreiche ich mein konkretes Ziel, welche Werkzeuge kann ich mir aneignen um meine Vorgaben umzusetzen?</li>
                </ul>
              </Fade>
              <Fade right>
                <p className='offer__list-item-appendum'>Oft quälen wir uns mit genau diesen Fragen und Gedankengängen. Hier komme ich als neutraler Berater und Coach ins Spiel. Über "Gesprächstherapeuthische" Ansätze werden wir dem ganzen nachgehen und die Situation nach und nach, wie ein Wollknäuel aufdröseln, und gemeinsam eine Lösung erarbeiten.</p>
                <button className='home__button buttonFill'>
                  <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.Contact}>
                    Zum unverbindlichen Erstgespräch
                  </NavLink>
                </button>
              </Fade>
            </div>
          </div>
          <Fade left>
            <div className='container'>
              <TitleText
                title='Finanzberatung'
                text='Die Welt verändert sich. Äußere Faktoren zwingen uns, unsere Möglichkeiten neu
                zu definieren. Ungewissen Zeiten bringen Chancen mit sich, Mut wird gerade jetzt und heute benötigt, vor allem im Bereich der Finanzen
                gilt es umzudenken und sich neu aufzustellen. Vermögen aufzubauen und es zu verwalten, es zu
                vermehren und gleichzeitig für unser Alter vorzusorgen sind Wunsch und Ziel gleichermaßen. 
                Kann ich dies als meine eigene Bank erreichen, geht so etwas überhaupt, gibt es in diesen Zeiten eine alternative zu
                klassischen Anlageformen? Ich bin Wissens- und Möglichkeitenvermehrer. Ich berate und
                coache Menschen, die in ihrer Vermögensvermehrung und Zukunftsvorsorge breiter aufgestellt
                sein wollen. Dazu informiere und erkläre ich alternative Anlageformen wie
                Blockchaintechnologie und DLT`s (Distributed Ledger Technology) wie beispielsweise
                Bitcoin, Ethereum, IOTA und viele weitere.'
              />
            </div>
          </Fade>
          <div className='offer__section-image-description-container container'>
            <Fade right >
              <div className='offer__section-image-wrapper'>
                <figure className='offer__image-wrapper'>
                  <img className='offer__image' src={MoneyTree} alt='Baeumchen waechst aus Geld' />
                </figure>
              </div>
            </Fade>
            <div className='offer__description-wrapper'>
              <Fade right big cascade>
                <ul className='offer__list-wrapper'>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Inflationvorsorge</span> - Inflation wird zu einem Begriff der Allgemeinheit, mein Geld wird immer weniger wert, aber kann man sich und sein Gespartes denn davor schützen?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Krisenvorsorge</span> - was soll ich tun wenn die Wirtschaft zusammenbricht, was wenn etwas passiert, wie und was ist heute denn noch sicher?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Vermögensaufbau</span>- kann ich mein Vermögen auch in kleinen Schritten anlegen und davon in der Zukunft profitieren, lohnt sich das denn überhaupt?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Edelmetalle</span> - sind Edelmetalle überhaupt noch sinnvoll, und warum sollte ich mir so etwas kaufen, und wenn wo macht man so etwas seriös?</li>
                  <li className='offer__list-item'><span className='offer__list-item-span'>Kryptowährungen</span> - Bitcoin und die anderen Währungen werden doch nur von Kriminellen genutzt, außerdem schaden sie der Umwelt, ich versteh außerdem nur Bahnhof beim Thema Kryptographie, ist mir viel zu komplex, oder ist das ein Trugschluss?</li>
                </ul>
              </Fade>
              <Fade right>
                <p className='offer__list-item-appendum'>Das Thema Finanzen betrifft heutzutage so gut wie jeden. Eine Unabhängigkeit und Freiheit wird häufig mit der eigenen finanziellen Lage zusammengebracht, aber nicht immer möchte man sich damit auseinandersetzen oder hat die Zeit dafür. Ich als unabhängiger Finanzberater und Coach berate und gebe mein Wissen in den verschiedenen Bereichen weiter und helfe bei den unterschiedlichen Themen Klarheit zu schaffen und der eigenen finanziellen Freiheit Schritt für Schritt näher zu kommen.</p>
                <button className='home__button buttonFill'>
                  <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.Contact}>
                    Zum unverbindlichen Erstgespräch
                  </NavLink>
                </button>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
