import { Outlet } from 'react-router-dom';

const Blog = ({posts}) => {
  return (
    <div className='blog'>
      <div className='container'>
        <div className='blog__container'>
            <h2 className='blog__title'>Blog</h2>
            <Outlet posts={posts} />
        </div>
      </div>
    </div>
  );
};

export default Blog;