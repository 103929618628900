import {useState} from 'react';

// Import Media
import LandscapeGlobe from '../media/landscape-lake-mountains-graphic.webp';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const ContactForm = () => {
  const [state, setState] = useState({name: '', email: '', message: ''});

  const handleChange = (e) => setState({...state, [e.target.name]: e.target.value});

  const handleSubmit = (e) => {
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({'form-name': 'contactForm', ...state}),
    })
      .then(() => alert('Success!'))
      .then(() => setState({name: '', email: '', message: ''}))
      .catch((error) => alert(error));
    e.preventDefault();
  };

  return (
    <div className='contactForm'>
      <div className='contactForm__wrapper'>
        <figure className='contactForm__img-wrapper'>
          <img
            className='contactForm__img'
            src={LandscapeGlobe}
            alt='Landschaft mit Graphik darüber'
          />
        </figure>
        <div className='contactForm__form-container'>
          <form className='contactForm__form' onSubmit={handleSubmit}>
            <h1 className='contactForm__title'>Kontaktformular</h1>
            <input
              className='contactForm__input'
              type='text'
              name='name'
              value={state.name}
              placeholder='Vor- und Nachname'
              onChange={handleChange}
              required
            />

            <input
              className='contactForm__input'
              type='email'
              name='email'
              value={state.email}
              placeholder='Email'
              onChange={handleChange}
              required
            />

            <textarea
              className='contactForm__textarea'
              name='message'
              placeholder='Nachricht schreiben...'
              value={state.message}
              onChange={handleChange}
              required></textarea>
            <button className='buttonFill' type='submit'>
              Senden
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
