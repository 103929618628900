import {useState} from 'react';
import {Twirl as Hamburger} from 'hamburger-react';

import NavigationItemsMobile from '../fragments/navigation-items-mobile';

function BurgerMenu() {
  const [isOpen, setOpen] = useState(false);

  const clickToCloseMenu = () => {
    setOpen(false);
  };

  return (
    <div className='mobileMenu'>
      <div
        className={`mobileMenu__hamburger-container ${
          isOpen && 'mobileMenu__hamburger-container--open'
        }`}>
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          direction='right'
          size={38}
          duration={0.5}
          color='#fff'
        />
      </div>
      <div
        className={`mobileMenu__content-container ${
          isOpen
            ? `mobileMenu__content-container--open ${(document.body.style.overflow = 'hidden')}`
            : `${(document.body.style.overflow = 'unset')}`
        }`}>
        <nav className='mobileMenu__nav-link-container'>
          <NavigationItemsMobile isOpen={isOpen} clickToCloseMenu={clickToCloseMenu} />
        </nav>
      </div>
    </div>
  );
}

export default BurgerMenu;
