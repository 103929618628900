import React from 'react';
import { NavLink } from 'react-router-dom';

// import components from react-bootstrap
import Card from 'react-bootstrap/Card';
import Blog from '../pages/blog';

const BlogPosts = ({posts}) => {
  return (
    <React.Fragment>
      <div className='blogposts'>
        {!posts ? ( <Blog />
          ) : (
            posts.map(({ slug, datePublished, coverPhoto, title, content, id, author }) => {
              const markup = { __html: content.html.length > 150 ? content.html.substring(0, 147) + '...' : content.html }
              
              return (
                <NavLink className='blogposts__link-card-container' to={`/blog/post/${slug}`} key={id}>
                  <Card className='blogposts__card'>
                      <figure className='blogposts__card-image-container'>
                        <Card.Img variant="top" src={coverPhoto.url} className='blogposts__card-image' />
                      </figure>
                      <Card.Body className='blogposts__card-body'>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text className="blogposts__text" dangerouslySetInnerHTML={markup}>
                        </Card.Text>
                        <div className='blogposts__card-button-container'>
                          <button className='blogposts__card-button buttonFill'>Weiterlesen</button>
                        </div>
                      </Card.Body>
                  </Card>
                </NavLink>
              )
            })
        )}
      </div>
    </React.Fragment>
  );
}

export default BlogPosts;