import StepcardList from '../data/stepscard-list';
import Fade from 'react-reveal/Fade';

const StepsCard = () => {
  return (
    <div className='cards'>
      <Fade left big cascade>
        <div className='cards__wrapper container'>
          {StepcardList.map((card, index) => (
            <div key={index} className='cards__card-container'>
              <div className='cards__card-icon-wrapper'>
                <div className='cards__card-icon-background'>{card.icon}</div>
              </div>
              <div className='cards__card-text-content-wrapper'>
                <h2 className='cards__card-title'>{card.title}</h2>
                <p className='cards__card-text'>{card.text}</p>
              </div>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default StepsCard;
