import {NavLink} from 'react-router-dom';
import itemsList from '../data/navitem-list';

function NavigationItemsDesktop() {
  
  return (
    <>
      {itemsList.map((item, index) => {
        return item.title === 'Startseite' 
          ?
          <NavLink
          key={index}
          end
          exact="true"
          className={({isActive}) =>
          `nav-item hover-underline-animation ${isActive ? 'nav-item--active' : ''}`
          }
          to={item.route} >
            {item.title}
          </NavLink>
          : 
          <NavLink
            key={index}
            className={({isActive}) =>
            `nav-item hover-underline-animation ${isActive ? 'nav-item--active' : ''}`
            }
            to={item.route} >
              {item.title}
          </NavLink>
        })}
    </>
  );
}

export default NavigationItemsDesktop;
