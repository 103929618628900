import React, { useEffect, useState } from 'react';
import { request } from 'graphql-request';

// import components
import Header from './components/header';
import Footer from './components/footer';
import AppRouter from './components/approuter';

// import cookiebanner
import CookieBanner from './components/cookie-consent-banner';


function App() {
  const [posts, setPosts] = useState(null)

  const API_KEY = process.env.REACT_APP_HYGRAPH_API_KEY;
  const API_URL = `https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/${API_KEY}/master`;
  
  useEffect(() => {
    const fetchPosts = async () => {
      const {posts} = await request (
        API_URL,
        `
          {
            posts {
              id
              datePublished
              slug
              title
              coverPhoto {
                url,
                handle,
              }
              author {
                name,
                avatar {
                  url
                }
              }
              content {
                html
              }
            }
          }
        `
      );

      setPosts(posts)
    };
    fetchPosts()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
    <div className='app'>
      <Header />
      <AppRouter posts={posts} />
      <Footer />
      <CookieBanner />
    </div>
  );
}
export default App;
