import { Routes, Route } from 'react-router-dom';

// import pages
import Home from '../pages/home';
import Contact from '../pages/contactForm';
import Offer from '../pages/offer';
import Error from '../pages/error';
import About from '../pages/about-me';
import AGB from '../pages/agb';
import ImpressumDataProtection from '../pages/impressum-data-protection';
import Blog from '../pages/blog';
import BlogPosts from '../components/blogposts';
import BlogPost from '../components/blogpost';

// import custom routes
import MyRoutes from '../routes';

const AppRouter = ({posts}) => {
    return (
      <Routes>
        <Route path={MyRoutes.Home} end exact={true} element={<Home />} />
        <Route path={MyRoutes.Contact} element={<Contact />} />
        <Route path={MyRoutes.Offer} element={<Offer />} />
        <Route path={MyRoutes.Blog} element={<Blog posts={posts} />}>
          <Route index element={<BlogPosts posts={posts} />} />
          <Route path="/blog/post/:slug" element={<BlogPost posts={posts} />} />
        </Route>
        <Route path={MyRoutes.About} element={<About />} />
        <Route path={MyRoutes.Error} element={<Error />} />
        <Route path={MyRoutes.AGB} element={<AGB />} />
        <Route path={MyRoutes.ImpressumDataProtection} element={<ImpressumDataProtection />} />
      </Routes>
    );
  };

  export default AppRouter;