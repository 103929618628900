import {NavLink} from 'react-router-dom';
import Routes from '../routes';

import {Swiper, SwiperSlide} from 'swiper/react';

// Import Scroll Animation Lib
import Fade from 'react-reveal/Fade';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';
import 'swiper/scss/effect-cube';

import {Autoplay, EffectFade} from 'swiper';

// Import pages
import TitleText from '../components/title-text';
import HeaderImage from '../components/header-image';
import StepsCard from '../components/steps-card';

// Import media
import PersonCityBackground from '../media/person-city-background.webp';
import LandscapeLakeGraphic from '../media/landscape-lake-mountains-graphic.webp';
import CryptoCirclesTouch from '../media/crypto-circle-fingertouch.webp';
import MyFoto from '../media/bewerbungsfoto_3.webp';

import LautenbacherCoachingTextSlogan from '../media/logo/lautenbacher-coaching-text-black.svg';

const Home = () => {
  return (
    <div className='home'>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        effect={'fade'}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, EffectFade]}
        className='mySwiper'>
        <SwiperSlide>
          <HeaderImage
            headerImage={PersonCityBackground}
            headerImageAlt='Person vor dem Hintergrund einer Stadt'
            headerTitleImage={LautenbacherCoachingTextSlogan}
            headerTitleImageAlt='Lautenbacher Coaching Slogan'
            headerFetchPriority='high'
          />
        </SwiperSlide>
        <SwiperSlide>
          <HeaderImage
            headerImage={LandscapeLakeGraphic}
            headerImageAlt='Landschaft mit Bergen und See darüber Graphiken'
            headerTitleImage={LautenbacherCoachingTextSlogan}
            headerTitleImageAlt='Lautenbacher Coaching Slogan'
            headerFetchPriority='low'
          />
        </SwiperSlide>
        <SwiperSlide>
          <HeaderImage
            headerImage={CryptoCirclesTouch}
            headerImageAlt='Person berührt virtuelle Kryptocoinkreise'
            headerFetchPriority='low'
          />
        </SwiperSlide>
      </Swiper>
      <div className='home__section-container'>
        <Fade left>
          <div className='home__section-wrapper container'>
            <TitleText
              title='Warum Coaching?'
              text='Coaching unterstützt Menschen dabei Ziele zu definieren und zu erreichen, Hindernisse zu überwinden und ihr volles Potenzial zu entfalten. Es hilft dabei, Klarheit und Fokus zu schaffen, eigene Stärken und Schwächen zu erkennen und effektive Strategien zur persönlichen und beruflichen Entwicklung zu entwickeln. Coaching bietet eine unterstützende und ermutigende Umgebung, in der Menschen lernen können, ihre Fähigkeiten und Talente zu nutzen, um ihr Leben in die gewünschte Richtung zu lenken.'
            />
          </div>
          <button className='home__button buttonFill'>
            <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.Contact}>
              Zum unverbindlichen Gespräch
            </NavLink>
          </button>
        </Fade>
        <div className='offer__short-summary-container container'>
          <div className='offer__short-summary-wrapper'>
            <Fade left>
              <div className='offer__section-short-summary-image-wrapper'>
                <figure className='offer__short-summary-image-wrapper'>
                  <img className='offer__short-summary-image' src={MyFoto} alt='Coach Raphael Lautenbacher' />
                </figure>
              </div>
            </Fade>
            <Fade right>
              <div className='home__section-wrapper'>
                <TitleText
                  title='Wie funktioniert meine Beratung'
                  text='Du stehst mit deinem Anliegen und Bedürfnissen im Mittelpunkt. Dein zielführendes persönliches Coaching beginnt mit der Klärung von deinen Zielen und Wünschen. Durch gezieltes Fragen werden wir Herausforderungen und den Handlungsbedarf ermitteln um eine individuelle Lösung für dich zu erarbeiten. Ich begleite dich bei der Umsetzung und reflektiere mit dir die Ergebnisse. Evaluation und Nachhaltigkeit sichern den Erfolg des Coachings.'
                />
              </div>
            </Fade>
          </div>
        </div>
        <StepsCard />
        <div className='home__button-container container'>
          <button className='home__button buttonFill'>
              <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.Contact}>
                Zum unverbindlichen Gespräch
              </NavLink>
          </button>
          <button className='home__button buttonFill'>
              <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.Offer}>
                Zum Angebot
              </NavLink>
          </button>
        </div>
        <Fade right>
          <div className='home__section-wrapper container'>
            <TitleText
                title='Gründe für eine Finanz-Beratung'
                text='Ein persönliches Finanzberatungsgespräch bei mir kann dir helfen, deine finanziellen Ziele zu erreichen und deine finanzielle Zukunft zu sichern. Ein professioneller Finanzberater kann dir dabei helfen, eine individuelle und maßgeschneiderte Finanzstrategie zu entwickeln, die auf deine spezifischen Bedürfnisse und Ziele abgestimmt ist. Durch eine persönliche Beratung kannst du auch Fragen stellen und Unsicherheiten klären, um fundierte Entscheidungen zu treffen. So kannst du langfristig finanzielle Freiheit und Sicherheit erreichen und dein Leben nachhaltig verbessern. Nutze die Chance auf eine persönliche Finanzberatung, um dein finanzielles Potenzial voll auszuschöpfen und deine Träume zu verwirklichen.'
              />
              <div className='home__button-container container'>
                <button className='home__button buttonFill'>
                  <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.Offer}>
                    Zum Angebot Finanzberatung 
                  </NavLink>
                </button>
              </div>
            </div>
        </Fade>
        <Fade left>
          <div className='home__section-wrapper container'> 
            <TitleText
                title='Gründe für ein Life-Coaching'
                text='Ein persönliches Life Coaching mit mir kann dir helfen, dein volles Potenzial auszuschöpfen und dein Leben nachhaltig zu verbessern. Ich als professioneller Life Coach kann dich dabei unterstützen, deine Ziele und Träume zu definieren und effektive Strategien zu entwickeln, um sie zu erreichen. Im Coaching werden gezielte Fragen gestellt, um Hindernisse und Blockaden zu identifizieren und individuelle Lösungen zu erarbeiten. Dadurch wirst du in der Lage sein, Klarheit und Fokus zu schaffen, dein Selbstbewusstsein zu stärken und deine persönliche und berufliche Entwicklung voranzutreiben. Nutze die Chance auf ein persönliches Life Coaching, um dein Leben in die gewünschte Richtung zu lenken und das Beste aus dir herauszuholen.'
              />
              <div className='home__button-container container'>
                <button className='home__button buttonFill'>
                  <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.Offer}>
                    Zum Life Coaching Angebot
                  </NavLink>
                </button>
                <button className='home__button buttonFill'>
                  <NavLink style={{color: 'currentColor', textDecoration: 'none'}} to={Routes.About}>
                    Über mich
                  </NavLink>
                </button>
            </div>
          </div>
        </Fade>  
      </div>
    </div>
  );
};

export default Home;
