import {MdHearing} from 'react-icons/md';
import {TbClipboardList} from 'react-icons/tb';
import {GiStairsGoal} from 'react-icons/gi';
import {FaPeopleCarry} from 'react-icons/fa';
import {FaRegHandshake} from 'react-icons/fa';

const StepcardList = [
  {
    icon: <MdHearing />,
    title: 'Zuhören',
    text: 'Ein erstes entspanntes Gespräch um sich und die Umstände kennen zu lernen.',
  },
  {
    icon: <TbClipboardList />,
    title: 'Situation analyisieren und Klarheit schaffen',
    text: 'Nach einem ersten Kennenlernen wird die Situation in der Tiefe angesehen und analysiert. Es wird Klarheit geschaffen welche Möglichkeiten in welchem Zeitrahmen gut passen, realisitisch sind und welche potentiellen Strategien erfolgsversprechend sind.',
  },
  {
    icon: <GiStairsGoal />,
    title: 'Plan entwickeln',
    text: 'Durch die stattgefundene Analyse wird ein Plan entwickelt welche Strategien sinnvoll umzusetzen sind. Dieser Prozess wird pro-aktiv von mir begleitet und die notwendigen Schritte unterstützt.',
  },
  {
    icon: <FaPeopleCarry />,
    title: 'Gemeinsam Ziele erreichen',
    text: 'Der festgelegte Plan wird umgesetzt, die Ziele werden immer wieder anhand der stattfindenden Daten betrachtet. Wenn notwendig wird eingegriffen und an Stellschrauben gedreht um das angestrebte Ziel im Zeithorizont zu erreichen.',
  },
  {
    icon: <FaRegHandshake />,
    title: 'Ganzheitliches Coaching und langfristige Unterstützung',
    text: 'Ganzheitliche Betreuung und Coaching findet während und nach der Umsetzung der getroffenen Ziele und Maßnahmen, auf Wunsch, statt. Bei Fragen und Sorgen stehe ich Ihnen zur Verfügung.',
  },
];
export default StepcardList;
