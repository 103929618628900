const ImageText = (props) => {
  return (
    <div className='imageText'>      
        <div className='imageText__container'>
          <figure className='imageText__image-wrapper'>
            <img className='imageText__image' src={props.image} alt={props.imageAlt} />
          </figure>
          <div className='content'>
            <h2 className='imageText__description-title'>Über mich</h2>
            <p className='imageText__description'>{props.description}</p>
          </div>
        </div>
    </div>
  );
};

export default ImageText;
