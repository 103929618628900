function Error() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>That feels like an existential question, don't you think?</p>
        <p>Just kidding it´s ya boi, ya Error</p>
      </main>
    </>
  );
}

export default Error;
