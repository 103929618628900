const AGB = () => {
  return (
    <div className='agb'>
      <div className='agb__container'>
        <h1 className='agb__page-title'>AGB´s</h1>
        <ol className='container'>
          <li className='agb__list-title'>Vertragsgegenstand und Leistungen des Beraters</li>
          <div className='agb__list-text'>
            Der Berater wird im Bereich Finanz und Life Coaching sowie in beratender Tätigkeit 
            für den Auftraggeber tätig. Der Berater wird im Rahmen seiner Beratung folgende Leistungen erbringen: 
            Wissenstransfer und Beratung Finanzbezogener Themen, Hilfestellung bei technischen Fragestellungen und beratende Tätigkeit
            im zu fragestellungen des Klienten. Der Berater erbringt seine Leistungen als selbständiger Unternehmer in freiberuflicher
            Tätigkeit. Der Auftraggeber hat dem Berater gegenüber keine Weisungsbefugnis. Im Rahmen
            der Tätigkeit ist der Berater örtlich und zeitlich ungebunden. Es werden jedoch die
            Interessen beider Vertragsparteien berücksichtigt, sofern eine persönliche Anwesenheit
            des Beraters erforderlich ist. Es wird kein Arbeitsverhältnis begründet. Der Berater ist
            verpflichtet, die Leistungen grundsätzlich persönlich zu erbringen. Im Falle einer
            Erkrankung oder sonstigen Dienstverhinderung ist dies dem Auftraggeber unverzüglich
            mitzuteilen. Bedient sich der Berater im Einzelfall bei der Ausführung der Tätigkeit
            anderer Personen, insbesondere Spezialisten zu Einzelfragen, bleibt er für die
            ordnungsgemäße Erfüllung der Leistungen nach diesem Vertrag gegenüber dem Auftraggeber
            verantwortlich.
          </div>

          <li className='agb__list-title'>
            Finanzcoaching und Beratung - Vergütung Buchung von Einzelsitzungen Telefonische Sitzung
          </li>
          <div className='agb__list-text'>
            Präsenzsitzung/Online mit Videounterstützung Stundensatz (60
            Minuten) nach Absprache und Vereinbarung. Buchung von Paketen (nutzbar innerhalb eines Jahres ab Buchung) Paket mit
            5 Stunden (höheres Kontingent nach Vereinbarung), telefonisch Sitzung nach Vereinbarung und Absprache.
            Buchung von Workshops ab 2 oder mehr Personen nach Absprache und Vereinbarung.
            Online mit Videounterstützung, Präsenzworkshop, Buchung von Vorträgen nach Absprache.
            Die Vergütung wird der Berater dem Auftraggeber nach stattgefundener Sitzung innerhalb
            von 2 Wochen unter präzisen Angaben zu der ausgeführten Tätigkeit und ihrer Dauer in
            Rechnung stellen. Der Auftraggeber wird das Honorar innerhalb von 14 Tagen nach Erhalt
            einer ordnungsgemäßen Rechnung auf das folgende Konto überweisen: DE91701204008488665012
            Raphael Lautenbacher. Es obliegt dem Berater, für die Abführung von Steuern,
            insbesondere Einkommensteuer, und etwaigen Sozialversicherungsbeiträgen Sorge zu tragen.
          </div>
          <li className='agb__list-title'>Haftungsausschluss</li>
          <div className='agb__list-text'>
            Es wird keine Haftung übernommen für die in der Beratung stattgefundene Tätigkeit und
            Aussagen übernommen. Etwaige Verluste durch Anlage in verschiedenen Assets und Formen
            sind nicht auf die Beratung zurück zu führen. Beratende Tätigkeit im Life Coaching kann zu 
            erheblichen Veränderungen führen. Es kann keine Haftung für Kursverluste auf
            volatilen Märkten übernommen werden. Die in der Beratung getätigten Aussagen finden nach
            besten Wissen und Gewissen statt. Der Klient ist sich diesem Umstand bewusst und
            akzeptiert jeglichen Haftungsausschluss.
          </div>
          <li className='agb__list-title'>Krankheit, Arbeitsverhinderung und Urlaub</li>
          <div className='agb__list-text'>
            Der Berater hat keinen Anspruch auf Vergütung, wenn er infolge Krankheit oder sonstigen
            Gründen an der Ausübung der Tätigkeit verhindert ist. Verschwiegenheit Über alle während
            der Tätigkeit bekannt gewordenen Geschäfts- und Betriebsgeheimnisse ist während und nach
            Beendigung des Vertragsverhältnisses Stillschweigen zu bewahren.
          </div>
          <li className='agb__list-title'>Schlussbestimmungen</li>
          <div className='agb__list-text'>
            Termine: Angaben gelten für Termine von Montag bis Donnerstag von 09:00 - 20:00 Uhr,
            Freitags 10:00 - 15:30 Uhr. Zu anderen Zeiten nach Absprache. Mindestdauer
            einer Sitzung, gebuchtes Format, Abrechnung darüber hinaus in 30 Minuteneinheiten.
            Verschiebung von Terminen: Sitzungen mit einer Dauer von bis zu 2 gebuchten Stunden
            können bis 72 Stunden vorher einmalig verschoben werden. Hier gelten die Wochentage
            Montag-Freitag (ohne Feiertage). Längere Sitzungen von mehr als 2 gebuchten
            Stundeneinheiten (120min) innerhalb von 7 Tagen mindestens 7 Tage im voraus. Sie werden
            ansonsten voll in Rechnung gestellt. Sollte es aus diversen Gründen nicht möglich sein
            eine Präsenzsitzung wahrzunehmen, ist das wechseln auf Online/Telefonsitzung nach
            Absprache möglich. Zahlungsweise ist grundsätzlich als Überweisung vollständig zu
            begleichen (in Ausnahmefällen und in Absprache bar). Spesen: Reise- und
            Unterkunftskosten sind nicht in den Stundensätzen enthalten. Werden nach Zeit und
            Aufwand vom Auftraggeber getragen. Maßgeblich individuelle Vereinbarung. Telefonischer
            Kontakt nach Absprache. Sollte eine der Bestimmungen in diesem Vertrag unwirksam sein
            oder werden, bleiben die übrigen Bestimmungen weiterhin bestehen. Die Parteien werden
            die unwirksame Bestimmung durch eine wirksame Bestimmung mit möglichst identischer
            Regelungswirkung ersetzen, die sie vereinbart hätten, wenn sie die Unwirksamkeit der
            Bestimmung von Beginn an gekannt hätten.
          </div>
        </ol>
      </div>
    </div>
  );
};

export default AGB;
