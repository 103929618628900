const TitleText = (props) => {
  return (
    <>
      <div className='titleText-container ' lang='de'>
        <h2 className='titleText-container__title'>{props.title}</h2>
        <p className='titleText-container__text'>{props.text}</p>
      </div>
    </>
  );
};

export default TitleText;
